import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: window.env.SENTRY_LARAVEL_DSN,
    tracesSampleRate: parseFloat(window.env.SENTRY_TRACES_SAMPLE_RATE),
    environment: window.env.SENTRY_ENVIRONMENT,
    ignoreErrors: [
        /rejected due to: Widget has been unmounted before the user/
    ],
});

window.Sentry = Sentry;
